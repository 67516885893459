import { createRouter, createWebHashHistory } from "vue-router";
import Home from "./components/Home.vue";
import Login from "./components/Login.vue";
import Register from "./components/Register.vue";

// lazy-loaded components
const Profile = () => import("./components/Profile.vue");
const BoardAdmin = () => import("./components/BoardAdmin.vue");
const BoardAds = () => import("./components/Ads.vue");
const BoardModerator = () => import("./components/BoardModerator.vue");
const BoardUser = () => import("./components/BoardUser.vue");
const EmailVerification = () => import("./components/EmailVerification.vue");
const EditUserSettings = () => import("./components/EditUserSettings.vue");
const AdGallery = () => import("./components/AdGallery.vue");
const Vehicle360View = () => import("./components/Vehicle360View.vue");
const ActiveSessions = () => import("./components/ActiveSessions.vue");
const Errors = () => import("./components/Errors.vue");

// layout imports
import DefaultLayout from './layouts/DefaultLayout.vue';
import EmptyLayout from './layouts/EmptyLayout.vue';

const routes = [
  {
    path: "/avto360/:userId/:vehicleId/:imageName",
    name: "vehicle360View",
    component: Vehicle360View,
    props: true,
    meta: { layout: EmptyLayout, title: 'Vehicle 360 View' },
  },
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { layout: DefaultLayout, title: 'DOMOV' },
  },
  {
    path: "/edit-user-settings/:userId",
    name: "editUserSettings",
    component: EditUserSettings,
    meta: { layout: DefaultLayout, title: 'NASTAVITVE UPORABNIKA' },
  },
  {
    path: "/home",
    component: Home,
    meta: { layout: DefaultLayout, title: 'DOMOV' },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { layout: DefaultLayout, title: 'PRIJAVA' },
  },
  {
    path: "/register",
    component: Register,
    meta: { layout: DefaultLayout, title: 'REGISTRACIJA' },
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: { layout: DefaultLayout, title: 'PROFIL' },
  },
  {
    path: "/ads",
    name: "ads",
    component: BoardAds,
    meta: { layout: DefaultLayout, title: 'OGLASI' },
  },
  {
    path: "/ads/:id",
    name: "adGallery",
    component: AdGallery,
    meta: { layout: DefaultLayout, title: 'UREJANJE OGLASA' },
  },
  {
    path: "/admin",
    name: "admin",
    component: BoardAdmin,
    meta: { layout: DefaultLayout, title: 'Klienti', requiresAuth: true, roles: ['ROLE_ADMIN'] },
  },
  {
    path: "/mod",
    name: "moderator",
    component: BoardModerator,
    meta: { layout: DefaultLayout, title: 'Moderator Board', requiresAuth: true, roles: ['ROLE_MODERATOR'] },
  },
  {
    path: "/user",
    name: "user",
    component: BoardUser,
    meta: { layout: DefaultLayout, title: 'User Board', requiresAuth: true, roles: ['ROLE_USER'] },
  },
  {
    path: '/verify-email',
    name: 'EmailVerification',
    component: EmailVerification,
    meta: { layout: DefaultLayout, title: 'Email Verification' },
  },
  {
    path: '/reports/active-sessions',
    name: 'activeSessions',
    component: ActiveSessions,
    meta: { layout: DefaultLayout, title: 'AKTIVNE SEJE', requiresAuth: true, roles: ['ROLE_ADMIN']},
  },
  {
    path: '/reports/errors',
    name: 'errors',
    component: Errors,
    meta: { layout: DefaultLayout, title: 'NAPAKE', requiresAuth: true, roles: ['ROLE_ADMIN']},
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  console.log('Začetek usmerjanja');
  console.log(`Usmeritev iz ${from.fullPath} v ${to.fullPath}`);

  const { requiresAuth, roles } = to.meta;
  const loggedIn = !!localStorage.getItem('user');
  const user = JSON.parse(localStorage.getItem('user'));

  if (requiresAuth && !loggedIn) {
    console.log('Preusmerjanje na prijavo');
    next({ name: 'login' });
  } else if (requiresAuth && roles && !user.roles.some(role => roles.includes(role))) {
    console.log('Nimam dovoljenj, preusmerjam domov');
    next({ name: 'home', query: { message: 'Za to stran nimate dovoljenj.' } });
  } else {
    console.log('Nadaljujem do cilja');
    console.log(`Parametri: ${JSON.stringify(to.params)}`);
    next();
  }
});


export default router;
