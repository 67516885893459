<template>
  <!-- Dinamično prikazovanje layouta na podlagi trenutne poti -->

  <div v-if="$route.meta.layout">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import EmptyLayout from '@/layouts/EmptyLayout.vue';

export default {
  computed: {
    layout() {
      const currentRouteLayout = this.$route.meta.layout;
      //console.log("Trenutna pot meta layout:", currentRouteLayout);

      // Direktno vrni ustrezen layout na podlagi trenutne poti
      if (currentRouteLayout) {
       // console.log("Naloži layout:", currentRouteLayout.name);
        return currentRouteLayout;
      } else {
        //console.log("Naloži privzeti layout: DefaultLayout");
        return DefaultLayout;
      }
    },
  },
};
</script>