import AuthService from '../services/auth.service';

/**
 * Retrieves the user object stored in the local storage and parses it into a JavaScript object.
 *
 * @returns {Object} The user object retrieved from the local storage.
 */
const user = JSON.parse(localStorage.getItem('user'));
/**
 * Represents the initial state object.
 *
 * @type {{
 *    status: {
 *      loggedIn: boolean
 *    },
 *    user: Object | null
 * }}
 */
const initialState = user
    ? { status: { loggedIn: true }, user }
    : { status: { loggedIn: false }, user: null };

/**
 * Auth module.
 *
 * @module auth
 */
export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
          user => {
            commit('loginSuccess', user);
            return Promise.resolve(user);
          },
          error => {
            commit('loginFailure');
            return Promise.reject(error);
          }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
          response => {
            commit('registerSuccess');
            return Promise.resolve(response.data);
          },
          error => {
            commit('registerFailure');
            return Promise.reject(error);
          }
      );
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
      console.log('User roles:', user.roles); // Dodano za preverjanje vlog uporabnika
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    }
  }
};
