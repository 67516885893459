import axios from 'axios';
import router from '../router';  // Uporabite ustrezen uvoz za vaš router

const axiosInstance = axios.create();

// Dodajte interceptor za odzive
axiosInstance.interceptors.response.use(
    (response) => {
        // Če je odgovor uspešen, ga vrnite
        return response;
    },
    (error) => {
        // Če je napaka, preverite, ali je status 401 (nepooblaščeno)
        if (error.response && error.response.status === 401) {
            console.log('Intercepted 401 error, removing user and redirecting to login.');
            // Počistite lokalno shrambo
            localStorage.removeItem('user');
            // Preusmerite uporabnika na stran za prijavo
            if (router.currentRoute.value.name !== 'login') {
                router.push({ name: 'login' }).then(() => {
                    // Prikažite sporočilo uporabniku
                    alert('Vaša seja je potekla. Prosimo, prijavite se ponovno.');
                });
            }
        } else {
            // Če je uporabnik že na strani za prijavo, prikažite opozorilo takoj
            alert('Vaša seja je potekla. Prosimo, prijavite se ponovno.');

        }
        // Vrnite obljubo zavrnitve
        return Promise.reject(error);
    }
);

export default axiosInstance;
